import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import dayjs from 'dayjs';

import { CloseOutlined, CheckOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Button, Popover, DatePicker, Select } from 'antd';

import { DATE_TYPE } from 'src/consts';

import styles from './styles.module.scss';

@observer
class SinglePeriodDatePicker extends React.Component {
  render() {
    const { viewModel, callback } = this.props;
    return (
      <Popover
        placement="bottomRight"
        open={viewModel.open}
        trigger="click"
        onOpenChange={viewModel.onOpenChange}
        content={(
          <div className={styles.pickerPopover}>
            <div className={styles.selectRow}>
              <div className={styles.selectTitle}>
                日期範圍
              </div>
              <Select
                options={DATE_TYPE}
                value={viewModel.selected}
                onSelect={viewModel.onSelect}
                popupMatchSelectWidth={false}
                popupClassName={styles.sortMenu}
                className={clsx('sortSelect', styles.filterSelect)}
                suffixIcon={<CaretDownOutlined style={{ pointerEvents: 'none' }} />}
              />
            </div>
            <DatePicker.RangePicker
              className={styles.picker}
              value={viewModel.date}
              allowClear={false}
              changeOnBlur
              onCalendarChange={viewModel.onCalendarChange}
              disabledDate={(current) => {
                return current > dayjs().endOf('day');
              }}
            />
            <div className={styles.actionContainer}>
              <Button
                className={clsx(styles.btn, styles.cancel)}
                onClick={viewModel.onCancel}
                icon={<CloseOutlined />}
              >
                取消
              </Button>
              <Button
                className={clsx(styles.btn, styles.submit)}
                onClick={() => viewModel.onConfirm(callback)}
                icon={<CheckOutlined />}
              >
                套用
              </Button>
            </div>
          </div>
        )}
      >
        <div className={clsx(styles.selector)}>
          <div className={clsx(styles.text)}>
            {`${viewModel.dateCache?.[0].format('YYYY年 MM月 DD日')} ~ ${viewModel.dateCache?.[1].format('YYYY年 MM月 DD日')} `}
          </div>
          <CaretDownOutlined className={styles.icon} />
        </div>
      </Popover>
    );
  }
}

SinglePeriodDatePicker.propTypes = {
  viewModel: PropTypes.object,
  callback: PropTypes.func
};

SinglePeriodDatePicker.defaultProps = {
  viewModel: {},
  callback: () => { }
};


export default SinglePeriodDatePicker;
