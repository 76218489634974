import { makeObservable, observable, action, computed, reaction, runInAction } from 'mobx';
import { message } from 'antd';

import BulletinService from 'src/services/bulletin';
import PackageService from 'src/services/package';
import packageStore from 'src/stores/packageStore';
import optionsStore from 'src/stores/optionsStore';

import BulletinDrawerViewModel from './Drawer/viewModel';

export default class BulletinPageViewModel {
  @observable bulletins = [];

  @observable id = '';
  @observable keyword = '';
  @observable keywordCache = '';

  @observable anchor = null;
  @observable total = 0;

  @observable drawerViewModel = new BulletinDrawerViewModel(this);

  @observable isProcess = true;

  // > delete related
  @observable isModalOpen = false;
  @observable deleteTargetId = null;
  @observable deleteTargetName = null;

  @observable onSendTarget = {};

  @observable isSelectFreqModalOpen = false;

  @observable projectStatus = {};
  @observable packageStatus = {};

  @computed get getBulletinsParams() {
    return {
      limit: 20,
      ...(this.keywordCache && { keyword: this.keywordCache }),
      ...(this.anchor && { anchor: this.anchor })
    };
  }

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = async () => {
    if (packageStore.activePackageId) {
      await Promise.all([
        this.getBulletins(),
        this.getAnalysisLogs()
      ]);
    } else {
      const once = reaction(
        () => packageStore.activePackageId,
        async (id) => {
          if (id) {
            await Promise.all([
              this.getBulletins(),
              this.getAnalysisLogs()
            ]);
            once();
          }
        }
      );
    }
  };

  @action onChange = (e) => {
    this.keyword = e.target.value;
    if (!this.keyword) {
      this.onSubmit();
    }
  };

  @action onSubmit = async () => {
    this.keywordCache = this.keyword;

    this.anchor = null;
    this.bulletins = [];

    this.getBulletins();
  };

  @action onKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onSubmit();
    }
  };

  @action onReload = async () => {
    this.anchor = null;
    this.bulletins = [];
    this.keyword = '';
    this.keywordCache = '';

    await this.getBulletins();
  };

  @action getBulletins = async () => {
    this.isProcess = true;
    try {
      const { list, anchor, count } = await BulletinService.getBulletins(this.getBulletinsParams);

      runInAction(() => {
        this.bulletins = [...this.bulletins, ...list];
        if (!this.anchor) {
          this.total = count;
        }
        this.anchor = anchor;
      });
    } catch (error) {
      console.log(error);
    } finally {
      runInAction(() => {
        this.isProcess = false;
      });
    }
  };

  @action getAnalysisLogs = async () => {
    try {
      const { projectStatus, packageStatus } = await PackageService.getAnalysisLog(packageStore.activePackageId);

      runInAction(() => {
        this.projectStatus = projectStatus;
        this.packageStatus = packageStatus;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onAddBtnClick = () => {
    this.drawerViewModel.updateContent();
  };

  @action onEdit = (id) => {
    this.drawerViewModel.updateContent(id);
  };

  @action onSendClick = async (data) => {
    this.onSendTarget = data;

    if (data.frequencyData.length > 1) {
      this.onSelectFreqModalOpen();
    } else {
      await this.onSend();
    }
  };

  @action onSelectFreqModalOpen = () => {
    this.isSelectFreqModalOpen = true;
  };

  @action onSelectFreqModalClose = () => {
    this.isSelectFreqModalOpen = false;
    this.onSendTarget = {};
  };

  @action onSend = async (freq = this.onSendTarget.frequencyData?.[0]) => {
    try {
      optionsStore.setLoading('send request');
      await BulletinService.sendBulletin(this.onSendTarget.id, freq);
      runInAction(() => {
        message.success('發送通報成功！');
        this.isSelectFreqModalOpen = false;
      });
    } catch (error) {
      console.log(error);
      message.error('發送通報異常');
    } finally {
      optionsStore.setCompleted('send request');
    }
  };

  @action onDelete = (data) => {
    this.deleteTargetId = data.id;
    this.deleteTargetName = data.name;

    this.isModalOpen = true;
  };

  @action onDeleteConfirm = async () => {
    try {
      await BulletinService.deleteBulletin(this.deleteTargetId);

      runInAction(() => {
        this.isModalOpen = false;
      });

      await this.onReload();
    } catch (error) {
      console.log(error);
    }
  };

  @action onDeleteCancel = () => {
    this.deleteTargetId = null;
    this.deleteTargetName = null;

    this.isModalOpen = false;
  };
}
