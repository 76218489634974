import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import dayjs from 'dayjs';

import RightArrowIcon from 'src/assets/RightArrowIcon.svg';
import DiamondIcon from 'src/assets/DiamondIcon.svg';
import PaperIcon from 'src/assets/PaperIcon.svg';

import { BULLETIN_TOPICS_COUNT_OPTIONS, BULLETIN_FREQUENCY_OPTIONS } from 'src/consts';

import { CloseOutlined, CheckOutlined, PlusOutlined, CaretDownFilled } from '@ant-design/icons';
import { Button, Drawer, Input, Tooltip, Modal } from 'antd';
import Select from 'src/components/select';
import RangeDatePicker from 'src/components/datepicker';
import packageStore from 'src/stores/packageStore';

import SourceSelectModal from 'src/components/SourceSelectModal';

import BulletinContent from './Content';
import BulletinLevelSelectModal from './BulletinLevelSelectModal';
import BulletinSentimentSelectModal from './BulletinSentimentSelectModal';

import styles from './styles.module.scss';

@observer
class BulletinDrawer extends React.Component {
  render() {
    const { viewModel, onReload } = this.props;
    return (
      <>
        <Drawer
          open={viewModel.isOpen}
          onClose={viewModel.onDrawerClose}
          closable={false}
          bodyStyle={{ padding: 0, display: 'grid', gridTemplateRows: '1fr auto' }}
          className={clsx('bulletinDrawer', styles.drawerContainer)}
          width={650}
        >
          <div className={clsx('drawerContent', styles.contentContainer)}>
            <div className={styles.contentHeader}>
              <img src={DiamondIcon} alt="" />
              <div className={styles.headerTitle}>
                {viewModel.id ? i18n.t('bulletin_drawer_edit_title') : i18n.t('bulletin_drawer_title')}
              </div>
            </div>

            <div className={styles.section}>
              <div className={styles.row}>
                <div className={clsx(styles.rowTitle, viewModel.errorName && styles.errorTitle)}>
                  {i18n.t('bulletin_drawer_name_title')}
                </div>
                <div className={styles.rowContent}>
                  <Input
                    className={clsx(styles.rowInput, viewModel.errorName && styles.errorInput)}
                    placeholder={i18n.t('bulletin_drawer_name_placeholder')}
                    onChange={viewModel.onNameChange}
                    value={viewModel.name}
                  />
                  {
                    viewModel.errorName
                    && (
                      <div className={styles.errorNote}>
                        {i18n.t('bulletin_drawer_name_error')}
                      </div>
                    )
                  }
                </div>
              </div>

              <div className={styles.row}>
                <div className={clsx(styles.rowTitle, viewModel.errorTime && styles.errorTitle)}>
                  {i18n.t('bulletin_drawer_time_title')}
                </div>
                <div className={styles.rowContent}>
                  <Tooltip
                    title={i18n.t('bulletin_drawer_time_toottip')}
                    placement="bottom"
                  >
                    <RangeDatePicker
                      containerName="drawerContent"
                      className={clsx(styles.rowPicker, viewModel.errorTime && styles.errorPicker)}
                      disabledDate={(current) => current < dayjs().subtract(1, 'day').endOf('day') || current > dayjs(packageStore.packageEndDate).endOf('day')}
                      onChange={viewModel.onTimeChange}
                      value={viewModel.time}
                    />
                  </Tooltip>
                  {
                    viewModel.errorTime
                    && (
                      <div className={styles.errorNote}>
                        {i18n.t('bulletin_drawer_time_error')}
                      </div>
                    )
                  }
                </div>
              </div>

              <div className={clsx(styles.row, styles.radioRow)}>
                <div className={styles.rowTitle}>
                  {i18n.t('bulletin_drawer_type_title')}
                </div>
                <div className={styles.radioGroup}>
                  <div className={styles.radioItem}>
                    <input type="radio" className={styles.radio} defaultChecked={viewModel.type === 'email'} />
                    <div className={styles.radioName}>
                      {i18n.t('bulletin_drawer_type_email')}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.divider} />

              <div className={styles.row}>
                <div className={clsx(styles.rowTitle, viewModel.errorCount && styles.errorTitle)}>
                  {i18n.t('bulletin_drawer_count_title')}
                </div>
                <div className={styles.rowContent}>
                  <Select
                    containerName="drawerContent"
                    className={clsx(styles.rowSelect, styles.single, viewModel.errorCount && styles.errorSelect)}
                    placeholder={i18n.t('bulletin_drawer_count_placeholder')}
                    options={BULLETIN_TOPICS_COUNT_OPTIONS}
                    value={viewModel.topicAmount}
                    onChange={viewModel.onAmountChange}
                    suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
                  />
                  {
                    viewModel.errorCount
                    && (
                      <div className={styles.errorNote}>
                        {i18n.t('bulletin_option_count_error')}
                      </div>
                    )
                  }
                </div>
              </div>

              <div className={styles.row}>
                <div className={clsx(styles.rowTitle, viewModel.errorFrequency && styles.errorTitle)}>
                  {i18n.t('bulletin_drawer_frequency_title')}
                </div>
                <div className={styles.rowContent}>
                  <Select
                    containerName="drawerContent"
                    className={clsx(styles.rowSelect, styles.multi, viewModel.errorFrequency && styles.errorSelect)}
                    placeholder={i18n.t('bulletin_drawer_frequency_placeholder')}
                    options={BULLETIN_FREQUENCY_OPTIONS}
                    mode="multiple"
                    value={viewModel.frequency}
                    onChange={viewModel.onFrequencyChange}
                    suffixIcon={<CaretDownFilled style={{ pointerEvents: 'none' }} />}
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                  />
                  {
                    viewModel.errorFrequency
                    && (
                      <div className={styles.errorNote}>
                        {i18n.t('bulletin_drawer_frequency_error')}
                      </div>
                    )
                  }
                </div>
              </div>

            </div>
            <div className={styles.section}>

              <div className={styles.sectionHeaderContainer}>
                <img src={PaperIcon} alt="" />
                <div className={styles.sectionHeader}>
                  {i18n.t('bulletin_drawer_receiver_section_title')}
                </div>
              </div>

              <div className={styles.row}>
                <div className={styles.rowTitle}>
                  {i18n.t('bulletin_drawer_receiver_new_title')}
                </div>
                <div className={styles.emailInputContainer}>
                  <div className={styles.rowContent}>
                    <Input
                      className={clsx(styles.rowInput)}
                      placeholder={i18n.t('bulletin_drawer_receiver_new_placeholder')}
                      value={viewModel.newEmail}
                      onChange={viewModel.onReceiverEmailChange}
                      onKeyDown={viewModel.onNewEmailKeyDown}
                    />
                  </div>
                  <Button
                    icon={<PlusOutlined />}
                    className={styles.emailAddBtn}
                    onClick={viewModel.onAddReceiver}
                  >
                    {i18n.t('bulletin_drawer_receiver_btn')}
                  </Button>
                </div>
              </div>

              <div className={clsx(styles.row, styles.selectedReceiverRow)}>
                <div className={styles.rowTitle}>
                  {i18n.t('bulletin_drawer_receiver_title')}
                </div>
                <div className={styles.selectedReceiver}>
                  {
                    viewModel.receivers.length === 0
                      ? (
                        <div className={styles.receiverPlaceholder}>
                          {i18n.t('bulletin_drawer_receiver_placeholder')}
                        </div>
                      )
                      : (
                        <>
                          {
                            viewModel.receivers.map((el) => {
                              return (
                                <div
                                  className={styles.receiverCard}
                                  key={el}
                                >
                                  <div>{el}</div>
                                  <CloseOutlined
                                    onClick={() => viewModel.onRemoveModalOpen(el)}
                                  />
                                </div>
                              );
                            })
                          }
                        </>
                      )
                  }
                </div>
              </div>

              <div className={styles.divider} />
            </div>
            <div className={clsx(styles.section, styles.bulletinSection)}>
              {
                viewModel.contents.map((el) => {
                  return (
                    <BulletinContent
                      key={el.id}
                      viewModel={el}
                      isDeletable={viewModel.isDeletable}
                      onDelete={() => viewModel.onRemoveContent(el.id)}
                      onContentIdChange={viewModel.onContentIdChange}
                    />
                  );
                })
              }
              <Button
                icon={<PlusOutlined />}
                className={styles.addBtn}
                onClick={viewModel.onAddContent}
              >
                {i18n.t('bulletin_drawer_add_new_content')}
              </Button>
            </div>
          </div>
          <div className={styles.submitContainer}>
            <Button
              className={clsx(styles.btn, styles.submitBtn)}
              icon={<CheckOutlined />}
              onClick={() => viewModel.onSubmit(onReload)}
            >
              {i18n.t('bulletin_drawer_confirm')}
            </Button>
            <Tooltip
              title={viewModel.receivers.length === 0 && '請先設定收件人信箱'}
            >
              <Button
                className={clsx(styles.sendBtn, viewModel.receivers.length > 0 && styles.active)}
                onClick={() => viewModel.onSendNow(onReload)}
                disabled={viewModel.receivers.length === 0}
              >
                {i18n.t('bulletin_drawer_send_immediately')}
              </Button>
            </Tooltip>
          </div>
          <div
            className={styles.customizeBack}
            onClick={viewModel.onDrawerClose}
          >
            <img src={RightArrowIcon} alt="" />
          </div>
        </Drawer>
        <BulletinLevelSelectModal viewModel={viewModel.levelsViewModel} onSubmitCallback={viewModel.onLevelSelectCompleted} />
        <BulletinSentimentSelectModal viewModel={viewModel.sentimentViewModel} onSubmitCallback={viewModel.onSentimentSelectCompleted} />
        <SourceSelectModal viewModel={viewModel.sourceSelectViewModel} onSubmitCallback={viewModel.onSourceSelectCompleted} />
        <Modal
          open={viewModel.isRemoveModalOpen}
          onCancel={viewModel.onRemoveModalClose}
          centered
          closable={false}
          footer={null}
          width={500}
          className={styles.deleteModal}
          bodyStyle={{ height: 220 }}
        >
          <div className={styles.container}>
            <div className={styles.deleteText}>

              {`確認是否刪除信箱 ${viewModel.targetEmail} ？`}
            </div>
            <div className={styles.btnContainer}>
              <Button
                className={clsx(styles.btn, styles.confirm)}
                onClick={viewModel.onReceiverRemove}
              >
                確定
              </Button>
              <Button
                className={clsx(styles.btn, styles.cancel)}
                onClick={viewModel.onRemoveModalClose}
              >
                取消
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

BulletinDrawer.propTypes = {
  viewModel: PropTypes.object,
  onReload: PropTypes.func
};

BulletinDrawer.defaultProps = {
  viewModel: {},
  onReload: () => { }
};

export default BulletinDrawer;

