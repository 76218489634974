import i18n from 'src/i18n';

export const ROUTES = {
  Login: 'login',
  LoginSuccess: 'loginSuccess',
  Home: 'home',
  Topics: 'topics',
  Topic: 'topic', // > 單一話題庫存頁
  Opinion: 'opinion', // > 輿情單一話題庫存頁
  TopicsResult: 'topics-result', // > 話題列表
  OpinionResult: 'opinion-topics', // > 輿情列表
  Chart: {
    Main: 'chart',
    Industry: 'chart/industry',
    Brand: 'chart/brand',
    Series: 'chart/series',
    Product: 'chart/product'
  },
  Wordbook: {
    Main: 'wordbook',
    Industry: 'wordbook/industry',
    Brand: 'wordbook/brand',
    Series: 'wordbook/series',
    Product: 'wordbook/product'
  },
  DataReport: {
    Main: 'data-report',
    IndustryTrend: 'data-report/industry-trend',
    MarketDifferentiation: 'data-report/market-differentiation',
    BrandCompetition: 'data-report/brand-competition',
    BrandHealth: 'data-report/brand-health',
    BrandEffect: 'data-report/brand-effect',
    BrandRoutine: 'data-report/brand-routine'
  },
  Advance: {
    Main: 'advanced',
    Marketing7P: 'advanced/marketing-7p',
    MindShare: 'advanced/mind-share',
    StartProduct: 'advanced/start-product',
    Reputation: 'advanced/reputation'
  },
  Related: {
    Main: 'related',
    Brand: 'related/brand',
    Series: 'related/series',
    Product: 'related/product'
  },
  AdvanceSearch: 'advance-search',
  Bulletin: 'bulletin',
  AccountManage: 'account-manage',
  PackagesManage: 'package-manage',
  Maintenance: 'maintenance'
};

export const SHOW_PACKAGE_SELECTOR = [
  ROUTES.Topics,
  ROUTES.Bulletin,
  ROUTES.AdvanceSearch,
  ROUTES.Chart.Main,
  ROUTES.Wordbook.Main,
  ROUTES.Advance.Main
];

export const SHOW_TIME_SELECTOR = [
  ROUTES.Topics,
  ROUTES.Chart.Main
];

// ref: 主回文類別
export const POST_TYPE = [
  {
    // > 主回文
    value: 'all',
    label: i18n.t('filter_post_type_all')
  },
  {
    // > 主文
    value: 'post',
    label: i18n.t('filter_post_type_post')
  },
  {
    // > 回文
    value: 'comment',
    label: i18n.t('filter_post_type_comment')
  }
];

export const POST_TYPE_TC = {
  all: i18n.t('filter_post_type_all'),
  post: i18n.t('filter_post_type_post'),
  comment: i18n.t('filter_post_type_comment')
};

// ref: 聲量類型
export const WORD_OF_MOUTH_TYPE = [
  {
    // > 全部聲量
    value: 'all',
    label: i18n.t('filter_word_of_mouth_all')
  },
  {
    // > 自然聲量
    value: 'nature',
    label: i18n.t('filter_word_of_mouth_nature')
  },
  {
    // > 活動聲量
    value: 'campaign',
    label: i18n.t('filter_word_of_mouth_event')
  }
];

export const E_WOM_TYPE = {
  all: i18n.t('filter_word_of_mouth_all'),
  nature: i18n.t('filter_word_of_mouth_nature'),
  campaign: i18n.t('filter_word_of_mouth_event')
};

// ref: 情緒類型
export const SENTIMENT_TYPE = [
  {
    // > 偏正面
    value: 1,
    label: i18n.t('filter_sentiment_positive')
  },
  {
    // > 偏中性
    value: 0,
    label: i18n.t('filter_sentiment_neutral')
  },
  {
    // > 偏負面
    value: -1,
    label: i18n.t('filter_sentiment_negative')
  }
];

// ref: 來源類別
export const SOURCE_CATEGORY = [
  {
    // > 新聞
    value: 'news',
    label: i18n.t('filter_source_category_news')
  },
  {
    // > 論壇
    value: 'forum',
    label: i18n.t('filter_source_category_forum')
  },
  {
    // > FB
    value: 'facebook',
    label: 'facebook'
  },
  {
    // > ig
    value: 'ig',
    label: i18n.t('filter_source_category_ig')
  },
  {
    // > Threads
    value: 'threads',
    label: i18n.t('filter_source_category_threads')
  },
  {
    // > Youtube
    value: 'video',
    label: '影片'
  },
  {
    // > others
    value: 'others',
    label: i18n.t('filter_source_category_others')
  }
];

export const SOURCE_ORDER = ['new', 'forum', 'facebook', 'ig', 'threads', 'video', 'blog', 'others'];

export const SOURCE_CATEGORY_TYPE = {
  news: i18n.t('filter_source_category_news'),
  forum: i18n.t('filter_source_category_forum'),
  facebook: 'facebook',
  ig: i18n.t('filter_source_category_ig'),
  threads: i18n.t('filter_source_category_threads'),
  video: '影片',
  blog: '部落格',
  others: i18n.t('filter_source_category_others')
};

export const SOURCE_ORDER_TC = SOURCE_ORDER.map((el) => SOURCE_CATEGORY_TYPE[el]);

export const SOURCE_CATEGORY_TYPE_TC = {
  [`${i18n.t('filter_source_category_news')}`]: 'news',
  [`${i18n.t('filter_source_category_forum')}`]: 'forum',
  facebook: 'facebook',
  [`${i18n.t('filter_source_category_ig')}`]: 'ig',
  [`${i18n.t('filter_source_category_threads')}`]: 'threads',
  [`${i18n.t('filter_source_category_others')}`]: 'others',
  部落格: 'blog',
  影片: 'video'
};

// ref: sort 類別
export const OPINION_SORT_TYPE = [
  {
    // > 時間
    value: 'date',
    label: i18n.t('sort_type_date')
  },
  {
    // > 回應數
    value: 'commentCount',
    label: i18n.t('sort_type_comment_count')
  },
  {
    // > 分享數
    value: 'shareCount',
    label: i18n.t('sort_type_share_count')
  },
  {
    // > 點閱數
    value: 'viewCount',
    label: i18n.t('sort_type_view_count')
  }
];

// ref: sort 類別
export const SORT_TYPE = [
  {
    // > 聲量
    value: 'womCount',
    label: '聲量'
  },
  {
    // > 時間
    value: 'date',
    label: i18n.t('sort_type_date')
  },
  {
    // > 回應數
    value: 'commentCount',
    label: i18n.t('sort_type_comment_count')
  },
  {
    // > 分享數
    value: 'shareCount',
    label: i18n.t('sort_type_share_count')
  },
  {
    // > 點閱數
    value: 'viewCount',
    label: i18n.t('sort_type_view_count')
  }
];

// ref: 時間選項
export const DATE_TYPE = [
  {
    value: 'sevenDays',
    label: '最近7天'
  },
  {
    value: 'fourteenDays',
    label: '最近14天'
  },
  {
    value: 'thirtyDays',
    label: '最近30天'
  }
];

// ref: SSO身份
export const userSSOIdentity = [
  {
    value: 'user',
    label: i18n.t('user_sso_identity_employee')
  },
  {
    value: 'customer',
    label: i18n.t('user_sso_identity_customer')
  }
];

// ref: SSO帳號狀態
export const userSSOStatus = [
  {
    value: 'activate',
    label: i18n.t('user_sso_status_active')
  },
  {
    value: 'deactivate',
    label: i18n.t('user_sso_status_inactive')
  }
];

// ref: VOC+登入權限
export const userVOCLoginAuth = [
  {
    value: 'enable',
    label: i18n.t('user_voc_login_auth_allow')
  },
  {
    value: 'disable',
    label: i18n.t('user_voc_login_auth_disallow')
  }
];

// ref: VOC+帳號角色
export const userVocIdentity = [
  {
    value: 'admin',
    label: i18n.t('account_manage_voc_identity_admin')
  },
  {
    value: 'teamLeader',
    label: i18n.t('account_manage_voc_identity_leader')
  },
  {
    value: 'member',
    label: i18n.t('account_manage_voc_identity_member')
  },
  {
    value: 'customer',
    label: i18n.t('account_manage_voc_identity_customer')
  }
];

// ref: Home page drawer type:
export const HOME_PAGE_DRAWER = {
  Announcement: 'announcement',
  Service: 'service'
};


export const PACKAGE_SORT_TYPE = {
  PinDateDesc: 'pinDateDesc',
  LastChangeDateAsc: 'lastChangeDateAsc',
  LastChangeDateDesc: 'lastChangeDateDesc',
  CreateDateAsc: 'createDateAsc',
  CreateDateDesc: 'createDateDesc'
};

export const BULLETIN_TOPICS_COUNT_OPTIONS = [
  {
    value: 10,
    label: i18n.t('bulletin_option_count_ten')
  },
  {
    value: 20,
    label: i18n.t('bulletin_option_count_twenty')
  },
  {
    value: 50,
    label: i18n.t('bulletin_option_count_fifty')
  },
  {
    value: 100,
    label: i18n.t('bulletin_option_count_hundred')
  }
];

export const BULLETIN_FREQUENCY_OPTIONS = [
  {
    value: 'daily',
    label: i18n.t('bulletin_drawer_frequency_daily')
  },
  {
    value: 'weekly',
    label: i18n.t('bulletin_drawer_frequency_weekly')
  },
  {
    value: 'monthly',
    label: i18n.t('bulletin_drawer_frequency_monthly')
  }
];

export const E_BULLETIN_FREQUENCY_OPTIONS = {
  daily: i18n.t('bulletin_drawer_frequency_daily'),
  weekly: i18n.t('bulletin_drawer_frequency_weekly'),
  monthly: i18n.t('bulletin_drawer_frequency_monthly')
};

export const ADVANCE_SEARCH_OPTIONS = [
  {
    value: 'level',
    label: '階層',
    disabled: false,
    children: [
      {
        value: 'brand',
        label: i18n.t('common_brand'),
        disabled: false
      },
      {
        value: 'series',
        label: i18n.t('common_series'),
        disabled: false
      },
      {
        value: 'product',
        label: i18n.t('common_product'),
        disabled: false
      }
    ]
  },
  {
    value: 'source',
    label: '來源',
    disabled: false,
    children: [
      {
        value: 'category',
        label: i18n.t('source_category_title'),
        disabled: false
      },
      {
        value: 'website',
        label: i18n.t('source_website_title'),
        disabled: false
      },
      {
        value: 'channel',
        label: i18n.t('source_channel_title'),
        disabled: false
      }
    ]
  },
  {
    value: 'sentiment',
    label: i18n.t('filter_sentiment_title'),
    disabled: false
  },
  {
    value: 'featureSet',
    label: i18n.t('wordbook_page_name'),
    disabled: false
  }
];

// ref: 文字雲計算方式

export const WORD_INDEX_TYPE = [
  {
    value: 'frequency',
    label: i18n.t('chart_page_index_frequency')
  },
  {
    value: 'weight',
    label: i18n.t('chart_page_index_weighted')
  }
];

// ref: 文字雲計算方式

export const WORD_COUNT_TYPE = [
  {
    value: 20,
    label: i18n.t('chart_page_word_count_twenty')
  },
  {
    value: 50,
    label: i18n.t('chart_page_word_count_fifty')
  },
  {
    value: 100,
    label: i18n.t('chart_page_word_count_hundred')
  }
];

export const WORDBOOK_CHART_TYPE = [
  {
    id: 'words',
    name: '文字雲'
  },
  {
    id: 'radar',
    name: '雷達圖'
  }
];


export const ADVANCE_ANALYSIS_OPTIONS = [
  {
    id: 'setting',
    name: '分析設定'
  },
  {
    id: 'result',
    name: '圖表結果'
  }
];

export const MARKETING_7P_CHART_TYPE = [
  {
    id: 'radar',
    name: '雷達圖'
  },
  {
    id: 'words',
    name: '文字雲'
  }
];

export const REPUTATION_OPTIONS = [
  {
    id: 'brand',
    name: '品牌聲譽定位'
  },
  {
    id: 'product',
    name: '產品聲譽定位'
  }
];

export const DateOptions = [
  {
    id: 'seven',
    name: '最近七天'
  },
  {
    id: 'fourteen',
    name: '最近十四天'
  },
  {
    id: 'thirty',
    name: '最近三十天'
  }
];

export const RELATED_WORD_OPTIONS = [
  {
    id: 'who',
    name: '人'
  },
  {
    id: 'event',
    name: '事'
  },
  {
    id: 'when',
    name: '時'
  },
  {
    id: 'where',
    name: '地'
  },
  {
    id: 'what',
    name: '物'
  }
];

export const RELATED_WORD_TYPE = {
  who: '人',
  event: '事',
  when: '時',
  where: '地',
  what: '物'
};

export const RELATED_CHART_TYPE = [
  {
    id: 'words',
    name: '文字雲'
  }
];


export const LOG_STATUS = {
  WAITING: '尚未開始',
  BEGIN: '分析中',
  FAIL: '分析失敗',
  CANCEL: '終止',
  DONE: '分析成功'
};

export const CONDITION_KEY_TC = {
  brand: '品牌',
  series: '系列',
  product: '產品',
  category: '來源類型',
  website: '來源網站',
  channel: '來源頻道',
  sentiment: '情緒',
  featureCategory: '特性群組'
};

export const TOPICS_CSV_TITLE_ROW = [
  { key: 'order', header: '序號' },
  { key: 'brand', header: '品牌' },
  { key: 'series', header: '系列' },
  { key: 'product', header: '產品' },
  { key: 'title', header: '標題' },
  { key: 'content', header: '內容' },
  { key: 'author', header: '發文ID' },
  { key: 'date', header: '發文日期' },
  { key: 'postType', header: '主回文' },
  { key: 'womType', header: '聲量類型' },
  { key: 'category', header: '來源類型' },
  { key: 'website', header: '來源網站' },
  { key: 'channel', header: '來源頻道' },
  { key: 'viewCount', header: '點閱數' },
  { key: 'shareCount', header: '分享數' },
  { key: 'commentCount', header: '總回文' },
  { key: 'url', header: '文章網址' },
  { key: 'womCount', header: '口碑數' },
  { key: 'sentiment', header: '情緒' }
];

export const OPINION_CSV_TITLE_ROW = [
  { key: 'order', header: '序號' },
  { key: 'title', header: '標題' },
  { key: 'content', header: '內容' },
  { key: 'author', header: '發文ID' },
  { key: 'date', header: '發文日期' },
  { key: 'postType', header: '主回文' },
  { key: 'category', header: '來源類型' },
  { key: 'website', header: '來源網站' },
  { key: 'channel', header: '來源頻道' },
  { key: 'viewCount', header: '點閱數' },
  { key: 'shareCount', header: '分享數' },
  { key: 'commentCount', header: '總回文' },
  { key: 'url', header: '文章網址' }
];
