import { makeObservable, observable, action, computed, runInAction, reaction } from 'mobx';
import i18n from 'src/i18n';
import { ROUTES } from 'src/consts';
import packageStore from 'src/stores/packageStore';
import userStore from 'src/stores/userStore';
import { message } from 'antd';

import HomeIcon from 'src/assets/MenuIcons/HomeIcon.svg';
import HomeIconActive from 'src/assets/MenuIcons/HomeIconActive.svg';
import TopicIcon from 'src/assets/MenuIcons/TopicIcon.svg';
import TopicIconActive from 'src/assets/MenuIcons/TopicIconActive.svg';
import ChartIcon from 'src/assets/MenuIcons/ChartIcon.svg';
import ChartIconActive from 'src/assets/MenuIcons/ChartIconActive.svg';
import WordbookIcon from 'src/assets/MenuIcons/WordbookIcon.svg';
import WordbookIconActive from 'src/assets/MenuIcons/WordbookIconActive.svg';
import DataReportIcon from 'src/assets/MenuIcons/DataReportIcon.svg';
import DataReportIconActive from 'src/assets/MenuIcons/DataReportIconActive.svg';
import AdvanceIcon from 'src/assets/MenuIcons/AdvanceIcon.svg';
import AdvanceIconActive from 'src/assets/MenuIcons/AdvanceIconActive.svg';
import RelatedIcon from 'src/assets/MenuIcons/RelatedIcon.svg';
import RelatedIconActive from 'src/assets/MenuIcons/RelatedIconActive.svg';
import AdvanceSearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';
import AdvanceSearchIconActive from 'src/assets/MenuIcons/SearchIconActive.svg';
import BulletinIcon from 'src/assets/MenuIcons/BulletinIcon.svg';
import BulletinIconActive from 'src/assets/MenuIcons/BulletinIconActive.svg';
import AccountManageIcon from 'src/assets/MenuIcons/AccountManageIcon.svg';
import AccountManageIconActive from 'src/assets/MenuIcons/AccountManageIconActive.svg';

export default class SideMenuViewModel {
  @observable currentSelected = [];
  @observable openKeys = [];
  @observable openKeysCache = null;
  @observable cachePage = '';

  @observable isCollapsed = false;
  @observable once = null;

  @computed get menu() {
    return [
      {
        key: ROUTES.Home,
        icon: <img src={this.currentSelected[0] === ROUTES.Home ? HomeIconActive : HomeIcon} alt="" style={{ width: 20, height: 20 }} />,
        children: null,
        label: i18n.t('home_page_name'),
        isShow: true
      },
      {
        key: ROUTES.Topics,
        icon: <img src={this.currentSelected[0] === ROUTES.Topics ? TopicIconActive : TopicIcon} alt="" style={{ width: 20, height: 20 }} />,
        children: null,
        label: i18n.t('topic_page_name'),
        isShow: userStore.topic && !packageStore.noAvailablePackages
      },
      {
        key: ROUTES.Chart.Main,
        icon: <img src={(this.currentSelected[0]?.includes(ROUTES.Chart.Main) && this.isCollapsed) ? ChartIconActive : ChartIcon} alt="" style={{ width: 20, height: 20 }} />,
        children: [
          {
            key: ROUTES.Chart.Industry,
            icon: null,
            children: null,
            label: i18n.t('common_industry'),
            isShow: packageStore.isIndustryOpen
          },
          {
            key: ROUTES.Chart.Brand,
            icon: null,
            children: null,
            label: i18n.t('common_brand'),
            isShow: packageStore.isBrandAvailable
          },
          {
            key: ROUTES.Chart.Series,
            icon: null,
            children: null,
            label: i18n.t('common_series'),
            isShow: packageStore.isSeriesAvailable
          },
          {
            key: ROUTES.Chart.Product,
            icon: null,
            children: null,
            label: i18n.t('common_product'),
            isShow: packageStore.isProductAvailable
          }
        ],
        label: i18n.t('chart_page_name'),
        isShow: userStore.chart && (packageStore.isIndustryOpen || packageStore.isBrandAvailable || packageStore.isSeriesAvailable || packageStore.isProductAvailable) && !packageStore.noAvailablePackages
      },
      {
        key: ROUTES.Wordbook.Main,
        icon: <img src={(this.currentSelected[0]?.includes(ROUTES.Wordbook.Main) && this.isCollapsed) ? WordbookIconActive : WordbookIcon} alt="" style={{ width: 20, height: 20 }} />,
        children: [
          {
            key: ROUTES.Wordbook.Industry,
            icon: null,
            children: null,
            label: i18n.t('common_industry'),
            isShow: packageStore.isIndustryOpen
          },
          {
            key: ROUTES.Wordbook.Brand,
            icon: null,
            children: null,
            label: i18n.t('common_brand'),
            isShow: packageStore.isBrandAvailable
          },
          {
            key: ROUTES.Wordbook.Series,
            icon: null,
            children: null,
            label: i18n.t('common_series'),
            isShow: packageStore.isSeriesAvailable
          },
          {
            key: ROUTES.Wordbook.Product,
            icon: null,
            children: null,
            label: i18n.t('common_product'),
            isShow: packageStore.isProductAvailable
          }
        ],
        label: i18n.t('wordbook_page_name'),
        isShow: userStore.wordbook
          && (packageStore.isIndustryOpen || packageStore.isBrandAvailable || packageStore.isSeriesAvailable || packageStore.isProductAvailable)
          && !packageStore.noAvailablePackages
      },
      {
        key: ROUTES.DataReport.Main,
        icon: <img src={(this.currentSelected[0]?.includes(ROUTES.DataReport.Main) && this.isCollapsed) ? DataReportIconActive : DataReportIcon} alt="" style={{ width: 20, height: 20 }} />,
        children: [
          {
            key: ROUTES.DataReport.IndustryTrend,
            icon: null,
            children: null,
            label: i18n.t('data_report_type_industry_trend'),
            isShow: userStore.isIndustryTrendAvailable
          },
          {
            key: ROUTES.DataReport.MarketDifferentiation,
            icon: null,
            children: null,
            label: i18n.t('data_report_type_market_differentiation'),
            isShow: userStore.isMarketDifferentiationAvailable
          },
          {
            key: ROUTES.DataReport.BrandCompetition,
            icon: null,
            children: null,
            label: i18n.t('data_report_type_brand_competition'),
            isShow: userStore.isBrandCompetitionAvailable
          },
          {
            key: ROUTES.DataReport.BrandHealth,
            icon: null,
            children: null,
            label: i18n.t('data_report_type_brand_health'),
            isShow: userStore.isBrandHealthAvailable
          },
          {
            key: ROUTES.DataReport.BrandEffect,
            icon: null,
            children: null,
            label: i18n.t('data_report_type_brand_effect'),
            isShow: userStore.isBrandEffectAvailable
          },
          {
            key: ROUTES.DataReport.BrandRoutine,
            icon: null,
            children: null,
            label: i18n.t('data_report_type_brand_routine'),
            isShow: userStore.isBrandRoutineAvailable
          }
        ],
        label: i18n.t('data_report_page_name'),
        isShow: ((
          (userStore.dataReport.industryTrend || userStore.dataReport.marketDifferentiation)
          || (userStore.dataReport.brandCompetition || userStore.dataReport.brandHealth))
          || (userStore.dataReport.brandEffect || userStore.dataReport.brandRoutine))
          && !packageStore.noAvailablePackages
      },
      {
        key: ROUTES.Advance.Main,
        icon: <img src={(this.currentSelected[0]?.includes(ROUTES.Advance.Main) && this.isCollapsed) ? AdvanceIconActive : AdvanceIcon} alt="" style={{ width: 20, height: 20 }} />,
        children: [
          {
            key: ROUTES.Advance.Marketing7P,
            icon: null,
            children: null,
            label: i18n.t('advance_type_marketing_7p'),
            isShow: userStore.advance.marketing7P
          },
          {
            key: ROUTES.Advance.MindShare,
            icon: null,
            children: null,
            label: i18n.t('advance_type_mind_share'),
            isShow: userStore.advance.mindShare
          },
          {
            key: ROUTES.Advance.StartProduct,
            icon: null,
            children: null,
            label: i18n.t('advance_type_star_product'),
            isShow: userStore.advance.starProduction
          },
          {
            key: ROUTES.Advance.Reputation,
            icon: null,
            children: null,
            label: i18n.t('advance_type_reputation'),
            isShow: userStore.isReputationAvailable
          }
        ],
        label: i18n.t('advance_page_name'),
        isShow: ((userStore.advance.marketing7P || userStore.advance.mindShare) || (userStore.advance.starProduction || userStore.isReputationAvailable)) && !packageStore.noAvailablePackages
      },
      {
        key: ROUTES.Related.Main,
        icon: <img src={(this.currentSelected[0]?.includes(ROUTES.Related.Main) && this.isCollapsed) ? RelatedIconActive : RelatedIcon} alt="" style={{ width: 20, height: 20 }} />,
        children: [
          {
            key: ROUTES.Related.Brand,
            icon: null,
            children: null,
            label: i18n.t('common_brand'),
            isShow: packageStore.isBrandOpen && packageStore.brand.length >= 1
          },
          {
            key: ROUTES.Related.Series,
            icon: null,
            children: null,
            label: i18n.t('common_series'),
            isShow: packageStore.isSeriesOpen && packageStore.series.length >= 1
          },
          {
            key: ROUTES.Related.Product,
            icon: null,
            children: null,
            label: i18n.t('common_product'),
            isShow: packageStore.isProductOpen && packageStore.product.length >= 1
          }
        ],
        label: i18n.t('related_page_name'),
        isShow: userStore.related && (packageStore.isBrandOpen || packageStore.isSeriesOpen || packageStore.isProductOpen) && !packageStore.noAvailablePackages
      },
      {
        key: ROUTES.AdvanceSearch,
        icon: <img src={this.currentSelected[0] === ROUTES.AdvanceSearch ? AdvanceSearchIconActive : AdvanceSearchIcon} alt="" style={{ width: 20, height: 20 }} />,
        children: null,
        label: i18n.t('advance_search_page_name'),
        isShow: userStore.advanceSearch && !packageStore.noAvailablePackages
      },
      {
        type: 'divider',
        isShow: userStore.notification || userStore.activeUserType !== 'customer'
      },
      {
        key: ROUTES.Bulletin,
        icon: <img src={this.currentSelected[0] === ROUTES.Bulletin ? BulletinIconActive : BulletinIcon} alt="" style={{ width: 20, height: 20 }} />,
        children: null,
        label: i18n.t('bulletin_page_name'),
        isShow: userStore.activeUserType !== 'customer' && !packageStore.noAvailablePackages
      },
      {
        key: ROUTES.AccountManage,
        icon: <img src={this.currentSelected[0] === ROUTES.AccountManage ? AccountManageIconActive : AccountManageIcon} alt="" style={{ width: 20, height: 20 }} />,
        children: null,
        label: i18n.t('account_manage_page_name'),
        isShow: userStore.activeUserType !== 'customer'
      }
    ];
  }

  @computed get filterMenu() {
    // TODO: 優化
    return this.menu.map((el) => {
      return ({
        ...el,
        children: el.children ? el.children.filter((c) => c.isShow).map((c) => ({
          key: c.key,
          icon: c.icon,
          children: c.children,
          label: c.label
        })) : null
      });
    }).filter((el) => el.isShow).map((p) => {
      if (p.type) {
        return ({ type: p.type });
      }
      return ({
        key: p.key,
        icon: p.icon,
        children: p.children,
        label: p.label
      });
    });
  }


  constructor() {
    makeObservable(this);
  }

  @action onMenuClick = (e, router) => {
    router.navigate(e.key);

    if (e.key.split('/').length < 2) {
      this.openKeys = [];
    }
  };

  @action onOpenChange = (keys) => {
    this.openKeys = keys;
  };

  @action didMount = (current) => {
    if (!packageStore.isLevelProcessing) {
      this.checkAvailable(current);
    }
  };

  @action didUpdate = (prev, current) => {
    const prevPathArr = prev.location.pathname.split('/');
    const currentPathArr = current.location.pathname.split('/');
    prevPathArr.shift();
    const prevPath = prevPathArr.join('/');
    currentPathArr.shift();
    const currentPath = currentPathArr.join('/');

    if (this.currentSelected.length === 0 || prevPath !== currentPath) {
      this.currentSelected = [currentPath];

      if (currentPathArr.length > 1) {
        this.openKeys = [currentPathArr[0]];
      } else {
        this.openKeys = [];
      }
    }

    if (!this.once) {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        (boolean) => {
          if (!boolean) {
            this.checkAvailable(current);
          }
        }
      );
      this.once = once;
    }
  };

  @action checkAvailable = (router) => {
    const path = window.location.pathname.slice(1);
    if (path === 'package-manage') {
      return;
    }
    const target = this.menu.find((el) => el.key === path);
    if (target) {
      if (!target.isShow) {
        message.error('未開放此功能，請與業務確認權限');
        router.navigate('/home', { replace: true });
      }
    } else {
      const inner = this.menu.find((el) => el.children && el.children.find((child) => child.key === path));
      if (inner) {
        const innerTarget = inner.children.find((el) => el.key === path);
        if (!(innerTarget && innerTarget.isShow && inner.isShow)) {
          message.error('未開放此功能，請與業務確認權限');
          router.navigate('/home', { replace: true });
        }
      }
    }
  };

  @action toggleCollapse = () => {
    if (!this.isCollapsed) {
      this.openKeysCache = this.openKeys[0];
    }
    this.isCollapsed = !this.isCollapsed;
    if (!this.isCollapsed) {
      setTimeout(() => {
        runInAction(() => {
          this.openKeys = [this.openKeysCache];
        });
      }, 200);
    }
  };
}
