import { makeObservable, observable, action, computed, reaction, runInAction } from 'mobx';
import i18n from 'src/i18n';
import { message } from 'antd';

import SourceService from 'src/services/source';

import optionsStore from 'src/stores/optionsStore';
import BulletinService from 'src/services/bulletin';

import SourceSelectModalViewModel from 'src/components/SourceSelectModal/viewModel';

import BulletinLevelSelectModalViewModel from './BulletinLevelSelectModal/viewModel';
import BulletinSentimentSelectModalViewModel from './BulletinSentimentSelectModal/viewModel';
import BulletinContentViewModel from './Content/viewModel';

export default class BulletinDrawerViewModel {
  @observable isOpen = false;

  @observable id = null;
  @observable name = '';
  @observable time = [];
  @observable type = 'email';
  @observable topicAmount = null;
  @observable frequency = [];

  @observable newEmail = '';
  @observable receivers = [];

  @observable contents = [];
  @observable contentsCache = [];

  @observable editContentId = null;

  @observable isValidate = false;
  @observable validation = {
    name: false,
    time: false,
    count: false,
    frequency: false
  };

  @observable isRemoveModalOpen = false;
  @observable targetEmail = null;

  @observable levelsViewModel = new BulletinLevelSelectModalViewModel();
  @observable sentimentViewModel = new BulletinSentimentSelectModalViewModel();
  @observable sourceSelectViewModel = new SourceSelectModalViewModel();

  @computed get isDeletable() {
    return this.contents.length > 1;
  }

  @computed get editContentLevels() {
    return this.contents.find((el) => el.id === this.editContentId)?.levelsViewModel;
  }

  @computed get editContentSentiment() {
    return this.contents.find((el) => el.id === this.editContentId)?.sentimentViewModel;
  }

  @computed get editContentSource() {
    return this.contents.find((el) => el.id === this.editContentId)?.sourceSelectViewModel;
  }

  @computed get errorName() {
    return this.isValidate && !this.validation.name;
  }

  @computed get errorTime() {
    return this.isValidate && !this.validation.time;
  }

  @computed get errorCount() {
    return this.isValidate && !this.validation.count;
  }

  @computed get errorFrequency() {
    return this.isValidate && !this.validation.frequency;
  }

  constructor(parent) {
    makeObservable(this);

    this.init(parent);
  }

  @action init = (parent) => {
    this.parent = parent;
  };

  @action getSource = async () => {
    if (optionsStore.isInQueue('source')) {
      return;
    }
    optionsStore.setLoading('source');
    try {
      const { source } = await SourceService.getPackageSources();
      runInAction(() => {
        this.sourceSelectViewModel.updateSources(source);
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('source');
    }
  };

  @action updateContent = async (bid) => {
    await this.getSource();
    if (bid) {
      await this.getContent(bid);
    } else {
      runInAction(() => {
        this.id = null;
      });
      this.refresh();
    }
    this.onDrawerOpen();
  };

  @action getContent = async (bid) => {
    if (optionsStore.isInQueue('content')) {
      return;
    }
    optionsStore.setLoading('content');
    try {
      const {
        id,
        name,
        time,
        amountToShow,
        frequencies,
        receivers,
        contents
      } = await BulletinService.getBulletinInfo(bid);
      runInAction(() => {
        this.id = id;
        this.name = name;
        this.time = time;
        this.topicAmount = amountToShow;
        this.frequency = [...frequencies];
        this.receivers = [...receivers];
        this.contents = contents.map((el) => BulletinContentViewModel.fromRes(el.settings, this));
        this.isValidate = false;
        this.validation = {
          name: false,
          time: false,
          count: false,
          frequency: false
        };
      });

      this.contents.forEach(async (vm) => {
        await vm.initCalculateText();
      });
    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('content');
    }
  };

  @action refresh = () => {
    this.name = '';
    this.nameCache = '';

    this.time = [];
    this.timeCache = [];

    this.topicAmount = null;
    this.topicAmountCache = null;

    this.frequency = [];
    this.frequencyCache = [];

    this.receivers = [];
    this.receiversCache = [];


    this.contents = [
      new BulletinContentViewModel({
        title: `${i18n.t('bulletin_drawer_add_new_content')}1`
      }, this)
    ];
    this.contentsCache = [...this.contents];
  };

  @action onDrawerOpen = () => {
    this.isOpen = true;
  };

  @action onDrawerClose = () => {
    this.isOpen = false;
  };

  // > name

  @action onNameChange = (e) => {
    this.name = e.target.value;
  };

  // > time

  @action onTimeChange = (e) => {
    this.time = e;
  };

  // > amount change

  @action onAmountChange = (e) => {
    this.topicAmount = e;
  };

  // > frequency change

  @action onFrequencyChange = (e) => {
    this.frequency = e;
  };

  // > receiver related

  @action onReceiverEmailChange = (e) => {
    this.newEmail = e.target.value;
  };

  @action onNewEmailKeyDown = (e) => {
    if (e.code === 'Enter' || e.keyword === 13) {
      if (e.nativeEvent.isComposing) {
        return;
      }
      this.onAddReceiver();
    }
  };

  @action onAddReceiver = () => {
    const testResult = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.newEmail);
    if (testResult) {
      this.receivers.push(this.newEmail);
      this.newEmail = '';
      return;
    }
    message.error('請輸入合格的信箱');
  };

  @action onRemoveModalOpen = (email) => {
    this.isRemoveModalOpen = true;

    this.targetEmail = email;
  };

  @action onRemoveModalClose = () => {
    this.targetEmail = null;

    this.isRemoveModalOpen = false;
  };


  @action onReceiverRemove = () => {
    this.receivers = this.receivers.filter((r) => r !== this.targetEmail);

    this.isRemoveModalOpen = false;
  };

  // > content related

  @action onAddContent = () => {
    this.contents.push(
      new BulletinContentViewModel({
        title: `${i18n.t('bulletin_drawer_add_new_content')}${this.contents.length + 1}`
      }, this)
    );
  };

  @action onRemoveContent = (id) => {
    this.contents = this.contents.filter((el) => el.id !== id);
  };

  @action onContentIdChange = (id) => {
    this.editContentId = id;
  };

  // > 240118 new for performance issue.
  // > call when submit to update text
  @action onLevelSelectCompleted = (data) => {
    const target = this.contents.find((item) => item.id === this.editContentId);
    if (!target) {
      return;
    }

    target.updateLevel(data);
  };

  @action onSourceSelectCompleted = (data) => {
    const target = this.contents.find((item) => item.id === this.editContentId);
    if (!target) {
      return;
    }

    target.updateSource(data);
  };

  @action onSentimentSelectCompleted = (data) => {
    const target = this.contents.find((item) => item.id === this.editContentId);
    if (!target) {
      return;
    }

    target.updateSentiment(data);
  };

  @action checkData = () => {
    return new Promise((res, rej) => {
      if (!this.isValidate) {
        this.isValidate = true;
      }

      if (!this.name) {
        this.validation.name = false;
      } else {
        this.validation.name = true;
      }

      if (!this.time[0] || !this.time[1]) {
        this.validation.time = false;
      } else {
        this.validation.time = true;
      }

      if (!this.topicAmount) {
        this.validation.count = false;
      } else {
        this.validation.count = true;
      }

      if (this.frequency.length === 0) {
        this.validation.frequency = false;
      } else {
        this.validation.frequency = true;
      }

      if (Object.values(this.validation).includes(false)) {
        message.error('基本設定有誤，請重新確認');
        rej();
      }

      res();
    });
  };

  // > submit related

  @action onSubmit = async (onReload) => {
    if (optionsStore.isLoading) {
      return;
    }
    optionsStore.setLoading('submit');
    try {
      await Promise.all([
        this.checkData(),
        ...this.contents.map((el) => el.onCheckContent())
      ]);

      const data = {
        title: this.name,
        startDate: this.time[0].startOf('day').toISOString(),
        endDate: this.time[1].endOf('day').toISOString(),
        method: {
          type: 'newsletter',
          settings: {
            amountToShow: this.topicAmount,
            frequencies: this.frequency,
            receivers: this.receivers
          }
        },
        contents: this.contents.map((el) => ({
          type: 'post',
          settings: {
            title: el.title,
            ...(el.selectedBrand && { searchKeywordLevel1Ids: [el.selectedBrand] }),
            ...(el.selectedProduct && { searchKeywordLevel3Ids: [el.selectedProduct] }),
            ...(el.selectedSeries && { searchTags: [{ parentId: el.selectedSeriesParent, id: el.selectedSeries }] }),
            channelIds: el.selectedChannels,
            sentiment: el.selectedSentiment
          }
        }))
      };

      if (!this.id) {
        await BulletinService.createBulletins(data);
      } else {
        await BulletinService.updateBulletin(this.id, data);
      }

      await onReload();
      runInAction(() => {
        this.editContentId = null;
        this.onDrawerClose();
        message.success('系統通報設定成功！');
      });

    } catch (error) {
      if (!error?.response) {
        return;
      }
      console.log(error);
    } finally {
      optionsStore.setCompleted('submit');
    }
  };

  @action onSendNow = async (cb) => {
    if (optionsStore.isLoading) {
      return;
    }
    optionsStore.setLoading('onSend');
    try {
      await Promise.all([
        this.checkData(),
        ...this.contents.map((el) => el.onCheckContent())
      ]);

      const data = {
        title: this.name,
        startDate: this.time[0].startOf('day').toISOString(),
        endDate: this.time[1].endOf('day').toISOString(),
        method: {
          type: 'newsletter',
          settings: {
            amountToShow: this.topicAmount,
            frequencies: this.frequency,
            receivers: this.receivers
          }
        },
        contents: this.contents.map((el) => ({
          type: 'post',
          settings: {
            title: el.title,
            ...(el.selectedBrand && { searchKeywordLevel1Ids: [el.selectedBrand] }),
            ...(el.selectedProduct && { searchKeywordLevel3Ids: [el.selectedProduct] }),
            ...(el.selectedSeries && { searchTags: [{ parentId: el.selectedSeriesParent, id: el.selectedSeries }] }),
            channelIds: el.selectedChannels,
            sentiment: el.selectedSentiment
          }
        }))
      };

      if (!this.id) {
        const id = await BulletinService.createBulletins(data);
        runInAction(() => {
          this.id = id;
        });
      } else {
        await BulletinService.updateBulletin(this.id, data);
      }

      await cb();
      runInAction(() => {
        message.success('系統通報設定成功！');
      });

      this.parent.onSendClick({
        id: this.id,
        frequencyData: this.frequency
      });
      // > send here!

    } catch (error) {
      console.log(error);
    } finally {
      optionsStore.setCompleted('onSend');
    }
  };
}
