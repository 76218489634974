import dayjs from 'dayjs';
import { BULLETIN_FREQUENCY_OPTIONS } from 'src/consts';

export default class BulletinListModel {
  constructor(data) {
    const { list, anchor, count } = data;

    const BULLETIN_FREQUENCY_OPTIONS_MAP = new Map(BULLETIN_FREQUENCY_OPTIONS.map((item) => [item.value, item.label]));

    this.list = list.map((el) => ({
      id: el._id,
      name: el.title,
      date: `${dayjs(el.startDate).format('YYYY/MM/DD')}-${dayjs(el.endDate).format('YYYY/MM/DD')}`,
      frequency: el.method.settings.frequencies.map((f) => BULLETIN_FREQUENCY_OPTIONS_MAP.get(f)).join('/'),
      frequencyData: el.method.settings.frequencies
    }));
    this.anchor = anchor;
    this.count = count;
  }


  static fromRes(data) {
    return new BulletinListModel(data);
  }
}
