import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import clsx from 'clsx';
import { Waypoint } from 'react-waypoint';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Table, Modal, Tooltip } from 'antd';

import SearchIcon from 'src/assets/MenuIcons/SearchIcon.svg';

import EditIcon from 'src/assets/Bulletin/EditIcon.svg';
import SendIcon from 'src/assets/Bulletin/SendIcon.svg';
import DeleteIcon from 'src/assets/Bulletin/DeleteIcon.svg';

import { E_BULLETIN_FREQUENCY_OPTIONS } from 'src/consts';
import { BULLETIN_TABLE } from 'src/consts/tables';
import InfoHeader from 'src/components/InfoHeader';

import BulletinDrawer from './Drawer';
import BulletinPageViewModel from './viewModel';

import styles from './styles.module.scss';

@observer
class BulletinPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new BulletinPageViewModel();
  }

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <InfoHeader
            breadcrumb={['系統通報']}
            showPicker={false}
            customizeRight={(
              <div className={styles.logContainer}>
                {
                  this.viewModel.packageStatus.stage
                  && (
                    <>
                      <div className={styles.projectLog}>
                        <span className={styles.title}>當日專案排程分析狀況：</span>
                        <span className={this.viewModel.projectStatus.stage === 'DONE' ? {} : styles.error}>
                          {this.viewModel.projectStatus.stage === 'DONE' ? '分析完成' : '分析異常'}
                        </span>
                        <span>
                          {`（ ${this.viewModel.projectStatus.beginAt} ~ ${this.viewModel.projectStatus.endAt} ）`}
                        </span>
                      </div>
                      <div className={styles.packageLog}>
                        <span className={styles.title}>當日數據包分析狀況：</span>
                        <span className={this.viewModel.packageStatus.stage === 'DONE' ? {} : styles.error}>
                          {this.viewModel.packageStatus.stage === 'DONE' ? '分析完成' : '分析異常'}
                        </span>
                        <span>
                          {`（ ${this.viewModel.packageStatus.beginAt} ~ ${this.viewModel.packageStatus.endAt} ）`}
                        </span>
                      </div>
                    </>
                  )
                }
              </div>
            )}
          />
          <div className={styles.addBtnContainer}>
            <Button
              icon={<PlusOutlined />}
              className={styles.addBtn}
              onClick={this.viewModel.onAddBtnClick}
            >
              {i18n.t('bulletin_add_btn')}
            </Button>
          </div>
          <div className={styles.resContainer}>
            <div className={styles.infoContainer}>
              <div className={styles.totalCountInfo}>
                {i18n.t('bulletin_total_count').replace('%count%', this.viewModel.total)}
              </div>
              <div className={styles.searchContainer}>
                <div className={styles.searchInputContainer}>
                  <input
                    className={styles.searchInput}
                    placeholder={i18n.t('bulletin_search_placeholder')}
                    value={this.viewModel.keyword}
                    onChange={this.viewModel.onChange}
                    onKeyDown={this.viewModel.onKeyDown}
                  />
                  <div
                    className={styles.searchBtn}
                    onClick={this.viewModel.onSubmit}
                  >
                    <img src={SearchIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.tableContainer}>
              <Table
                loading={this.viewModel.isProcess}
                rowClassName={styles.row}
                rowKey={(item) => item.id}
                dataSource={this.viewModel.bulletins}
                pagination={false}
                className="packagesTable"
                showSorterTooltip={false}
              >
                {
                  BULLETIN_TABLE.map((el) => {
                    if (el.key === 'action') {
                      return (
                        <Table.Column
                          title={(
                            <div className={styles.headerRow}>
                              {el.title}
                            </div>
                          )}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          className={styles.columns}
                          width="25%"
                          render={(_, record) => {
                            return (
                              <div>
                                <Tooltip
                                  title="編輯"
                                  placement="bottom"
                                >
                                  <Button
                                    type="link"
                                    icon={<img src={EditIcon} alt="" />}
                                    className={styles.actionBtn}
                                    onClick={() => this.viewModel.onEdit(record.id)}
                                  />
                                </Tooltip>
                                <Tooltip
                                  title="寄送通報"
                                  placement="bottom"
                                >
                                  <Button
                                    type="link"
                                    icon={<img src={SendIcon} alt="" />}
                                    className={styles.actionBtn}
                                    onClick={() => this.viewModel.onSendClick(record)}
                                  />
                                </Tooltip>
                                <Tooltip
                                  title="刪除通報"
                                  placement="bottom"
                                >
                                  <Button
                                    type="link"
                                    icon={<img src={DeleteIcon} alt="" />}
                                    className={styles.actionBtn}
                                    onClick={() => this.viewModel.onDelete(record)}
                                  />
                                </Tooltip>
                              </div>
                            );
                          }}
                        />
                      );
                    }
                    return (
                      <Table.Column
                        title={(
                          <div className={styles.headerRow}>
                            {el.title}
                          </div>
                        )}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        className={styles.columns}
                        width="25%"
                        render={(text, record) => {
                          if (!text) {
                            return <div>---</div>;
                          }
                          return <div>{text}</div>;
                        }}
                      />
                    );
                  })
                }
              </Table>
            </div>

          </div>
        </div>
        {
          this.viewModel.anchor
          && (
            <Waypoint
              onEnter={this.viewModel.getBulletins}
            />
          )
        }
        <BulletinDrawer viewModel={this.viewModel.drawerViewModel} onReload={this.viewModel.onReload} />
        <Modal
          open={this.viewModel.isModalOpen}
          onCancel={this.viewModel.onDeleteCancel}
          footer={null}
          closable={false}
          centered
          className={styles.deleteModal}
          bodyStyle={{ height: 220 }}
          width={500}
        >
          <div className={styles.container}>
            <div className={styles.deleteText}>
              {i18n.t('bulletin_delete_alert_content').replace('%name%', this.viewModel.deleteTargetName)}
            </div>
            <div className={styles.btnContainer}>
              <Button
                className={clsx(styles.btn, styles.confirm)}
                onClick={this.viewModel.onDeleteConfirm}
              >
                {i18n.t('bulletin_delete_alert_confirm')}
              </Button>
              <Button
                className={clsx(styles.btn, styles.cancel)}
                onClick={this.viewModel.onDeleteCancel}
              >
                {i18n.t('bulletin_delete_alert_cancel')}
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          open={this.viewModel.isSelectFreqModalOpen}
          onCancel={this.viewModel.onSelectFreqModalClose}
          footer={null}
          closable={false}
          centered
          className={styles.selectModal}
          bodyStyle={{ height: 220 }}
          width={500}
          zIndex={1001}
        >
          <div className={styles.container}>
            <div className={styles.text}>
              請選擇週期
            </div>
            <div className={styles.btnContainer}>
              {
                this.viewModel.onSendTarget.frequencyData?.map((el, i) => {
                  return (
                    <Button
                      key={el}
                      className={styles.btn}
                      onClick={() => this.viewModel.onSend(el)}
                    >
                      {E_BULLETIN_FREQUENCY_OPTIONS[el]}
                    </Button>
                  );
                })
              }
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

BulletinPage.propTypes = {

};

BulletinPage.defaultProps = {

};


export default BulletinPage;
