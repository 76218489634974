import React from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import isMobile from 'ismobilejs';

import { ROUTES } from 'src/consts';
import { AppStateContext } from 'src/stores';
import userStore from 'src/stores/userStore';

import CustomizeLayout from 'src/components/CustomizeLayout';
import HomePage from './HomePage';

import LoginPage from './LoginPage';
import LoginSuccessPage from './LoginSuccessPage';

// > manage related
import AccountManagementPage from './AccountManagementPage';
import PackagesManagementPage from './PackagesManagementPage';
import BulletinPage from './Bulletin';

import TopicPage from './TopicPage';
import TopicSinglePage from './TopicSinglePage';

import TopicsResultPage from './TopicsResultPage';

import OpinionSingleTopicPage from './OpinionSingleTopicPage';
import OpinionResultPage from './OpinionResultPage';

// > charts related
import ChartAnalysisPage from './ChartAnalysisPage';
import WordbookPage from './WordbookPage';

// > DataAnalysis
import IndustryTrendPage from './DataAnalysisPage/IndustryTrend';
import MarketDifferentiationPage from './DataAnalysisPage/MarketDifferentiation';
import BrandCompetitionPage from './DataAnalysisPage/BrandCompetition';
import BrandHealthPage from './DataAnalysisPage/BrandHealth';
import BrandEffectPage from './DataAnalysisPage/BrandEffect';
import BrandRoutinePage from './DataAnalysisPage/BrandRoutine';

// > advance analysis
import Marketing7Page from './AdvanceAnalysis/Marketing7P';
import MindSharePage from './AdvanceAnalysis/MindShare';
import StarProductPage from './AdvanceAnalysis/StarProduct';
import ReputationPage from './AdvanceAnalysis/Reputation';

import AdvanceSearchPage from './AdvanceSearch';

import RelatedAnalysisPage from './RelatedAnalysisPage';

import MaintenancePage from './MaintenancePage';

const router = createBrowserRouter([
  {
    path: '',
    element: <Navigate to="/home" replace />
  },
  {
    path: '/',
    element: <CustomizeLayout />,
    errorElement: <Navigate to="/login" replace />,
    // ! note: loader should return a value to prevent infinity loop.
    // ! cuz it is designed to get data before enter the page by 'useLoaderData' in element.
    loader: userStore.init,
    children: [
      {
        path: ROUTES.Home,
        element: <HomePage />
      },
      // > 話題列表
      {
        path: ROUTES.Topics,
        element: <TopicPage />
      },
      // > 圖表分析
      {
        path: `${ROUTES.Chart.Main}/:level`,
        element: <ChartAnalysisPage />
      },
      // > 分類詞庫
      {
        path: `${ROUTES.Wordbook.Main}/:level`,
        element: <WordbookPage />
      },
      // > 數據報告
      {
        path: ROUTES.DataReport.IndustryTrend,
        element: <IndustryTrendPage />
      },
      {
        path: ROUTES.DataReport.MarketDifferentiation,
        element: <MarketDifferentiationPage />
      },
      {
        path: ROUTES.DataReport.BrandCompetition,
        element: <BrandCompetitionPage />
      },
      {
        path: ROUTES.DataReport.BrandHealth,
        element: <BrandHealthPage />
      },
      {
        path: ROUTES.DataReport.BrandEffect,
        element: <BrandEffectPage />
      },
      {
        path: ROUTES.DataReport.BrandRoutine,
        element: <BrandRoutinePage />
      },
      // > 進階分析
      {
        path: ROUTES.Advance.Marketing7P,
        element: <Marketing7Page />
      },
      {
        path: ROUTES.Advance.MindShare,
        element: <MindSharePage />
      },
      {
        path: ROUTES.Advance.StartProduct,
        element: <StarProductPage />
      },
      {
        path: ROUTES.Advance.Reputation,
        element: <ReputationPage />
      },
      // > 關聯分析
      {
        path: `${ROUTES.Related.Main}/:level`,
        element: <RelatedAnalysisPage />
      },
      // > 交叉分析
      {
        path: ROUTES.AdvanceSearch,
        element: <AdvanceSearchPage />
      },
      // > 系統通報
      {
        path: ROUTES.Bulletin,
        element: <BulletinPage />
      },
      // > 帳號管理
      {
        path: ROUTES.AccountManage,
        element: <AccountManagementPage />
      },
      // > 數據包管理
      {
        path: ROUTES.PackagesManage,
        element: <PackagesManagementPage />
      },
      {
        path: '*',
        element: <Navigate to="/home" replace />
      }
    ]
  },
  {
    path: `${ROUTES.Topic}/:packageId/:vocId`,
    element: <TopicSinglePage />
  },
  {
    path: `${ROUTES.Opinion}/:vocId`,
    element: <OpinionSingleTopicPage />
  },
  {
    path: `${ROUTES.TopicsResult}`,
    element: <TopicsResultPage />
  },
  {
    path: `${ROUTES.OpinionResult}`,
    element: <OpinionResultPage />
  },
  {
    path: ROUTES.Login,
    element: <LoginPage />
  },
  {
    path: ROUTES.LoginSuccess,
    element: <LoginSuccessPage />
  },
  {
    path: ROUTES.Maintenance,
    element: <MaintenancePage />
  }
], {
  basename: '/'
});

class PagesRoot extends React.Component {
  static contextType = AppStateContext;

  render() {
    return (
      <>
        <Helmet>
          <meta name="viewport" content={`width=${isMobile(navigator.userAgent).phone || isMobile(navigator.userAgent).tablet ? '1200' : 'device-width'}, initial-scale=1`} />
        </Helmet>

        <RouterProvider router={router} />
      </>
    );
  }
}

export default PagesRoot;
