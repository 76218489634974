import { makeObservable, observable, action, computed, reaction } from 'mobx';
import dayjs from 'dayjs';
import packageStore from 'src/stores/packageStore';
import Cookies from 'js-cookie';

export default class MultiPeriodDatePickerViewModel {
  @observable open = false;
  @observable sync = true;

  @observable currentSelected = 'sevenDays';
  @observable currentSelectedCache = 'sevenDays';

  @observable previousSelected = '自訂';
  @observable previousSelectedCache = '自訂';

  // > 正式
  @observable currentDate = [dayjs().subtract(6, 'day'), dayjs()];
  @observable currentDateCache = [dayjs().subtract(6, 'day'), dayjs()];

  @observable previousDate = [dayjs().subtract(13, 'day'), dayjs().subtract(7, 'day')];
  @observable previousDateCache = [dayjs().subtract(13, 'day'), dayjs().subtract(7, 'day')];

  @observable disabledDateRange = [];

  @observable hasCompare = true;
  @observable hasCompareCache = true;

  @observable isInit = false;
  @observable initRule = null;

  @observable once = null;

  @computed get currentStart() {
    return this.currentDate?.[0]?.startOf('day').valueOf();
  }

  @computed get currentEnd() {
    return this.currentDate?.[1]?.endOf('day').valueOf();
  }

  @computed get previousStart() {
    return this.previousDate?.[0]?.startOf('day').valueOf();
  }

  @computed get previousEnd() {
    return this.previousDate?.[1]?.endOf('day').valueOf();
  }

  @computed get autoPrevious() {
    const [gte, lte] = this.currentDate;
    const totals = lte.endOf().diff(gte.startOf(), 'day') + 1;
    return [gte.subtract(totals, 'day'), lte.subtract(totals, 'day')];
  }

  @computed get overlapping() {
    if (this.currentStart > this.previousStart && this.previousEnd > this.currentEnd) {
      return [this.currentStart, this.currentEnd];
    }

    if (this.previousStart > this.currentStart && this.currentEnd > this.previousEnd) {
      return [this.previousStart, this.previousEnd];
    }

    if (this.currentStart > this.previousStart && this.previousEnd > this.currentStart) {
      return [this.currentStart, this.previousEnd];
    }

    if (this.previousStart > this.currentStart && this.currentEnd > this.previousStart) {
      return [this.previousStart, this.currentEnd];
    }

    return null;
  }

  @computed get currentTimeText() {
    return this.currentDateCache.map((el) => el.format('YYYY-MM-DD')).join('~');
  }

  @computed get previousTimeText() {
    return this.previousDateCache.map((el) => el.format('YYYY-MM-DD')).join('~');
  }

  @computed get autoPreviousTimeText() {
    return this.autoPrevious.map((el) => el.format('YYYY-MM-DD')).join('~');
  }

  constructor(initRule) {
    makeObservable(this);

    this.init(initRule);
  }

  @action init = (initRule = 'local') => {
    this.initRule = initRule;
    if (initRule !== 'local') {
      this.sync = false;
    }
    if (['range'].includes(initRule)) {
      return;
    }
    if (!packageStore.isLevelProcessing) {
      const endDateValue = Math.min(dayjs(packageStore.packageEndDate).valueOf(), dayjs().subtract(1, 'day').valueOf());
      this.updateDisabledDateRange([dayjs(packageStore.packageStartDate), dayjs(endDateValue)]);
      this.initTime();
    } else {
      const once = reaction(
        () => packageStore.isLevelProcessing,
        (bool) => {
          if (!bool) {
            const endDateValue = Math.min(dayjs(packageStore.packageEndDate).valueOf(), dayjs().subtract(1, 'day').valueOf());
            this.updateDisabledDateRange([dayjs(packageStore.packageStartDate), dayjs(endDateValue)]);
            this.initTime();
            once();
          }
        }
      );
    }
  };

  @action didMount = () => {
    const mainContainer = document.querySelector('.main');
    mainContainer.addEventListener('scroll', this.onCancel);

    const initReaction = reaction(
      () => packageStore.isLevelProcessing,
      (bool) => {
        if (!bool) {
          const endDateValue = Math.min(dayjs(packageStore.packageEndDate).valueOf(), dayjs().subtract(1, 'day').valueOf());
          this.updateDisabledDateRange([dayjs(packageStore.packageStartDate), dayjs(endDateValue)]);
          this.initTime();
        }
      }
    );
    this.once = initReaction;
  };

  @action willUnmount = () => {
    const mainContainer = document.querySelector('.main');
    mainContainer.removeEventListener('scroll', this.onCancel);

    // this.once();
  };

  // local, yesterday, range

  @action initTime = () => {
    if (this.initRule === 'local') {
      const currentTime = Cookies.get('currentTime');
      const previousTime = Cookies.get('previousTime');

      if (currentTime) {
        this.currentDate = currentTime.split(',').map((el) => dayjs(el));
        this.checkTimeAvailable(true, false);
        this.currentDateCache = this.currentDate;
        this.currentSelected = '自訂';
        this.currentSelectedCache = '自訂';
      }

      if (previousTime) {
        this.previousDate = previousTime.split(',').map((el) => dayjs(el));
        this.checkTimeAvailable(false, true);
        this.previousDateCache = this.previousDate;
        this.previousSelected = '自訂';
        this.previousSelectedCache = '自訂';
      }
    }

    if (this.initRule === 'range') {
      this.currentDate = [this.disabledDateRange[0], this.disabledDateRange[1]];
      this.currentDateCache = this.currentDate;
      this.currentSelected = '自訂';
      this.currentSelectedCache = '自訂';
    }

    if (this.initRule === 'yesterday') {
      const endDateValue = Math.min(dayjs(packageStore.packageEndDate).valueOf(), dayjs().subtract(1, 'day').valueOf());
      this.currentDate = [dayjs(endDateValue), dayjs(endDateValue)];
      this.currentDateCache = this.currentDate;
      this.currentSelected = '自訂';
      this.currentSelectedCache = '自訂';
    }

    this.isInit = true;
  };

  @action onOpenChange = (bool) => {
    if (!bool) {
      this.onCancel();
    }
    this.open = bool;
  };

  @action onCurrentCalendarChange = (dates, _, info) => {
    // if (info.range !== 'end') {
    //   return;
    // }
    this.currentDate = dates;
    this.currentSelected = '自訂';
  };

  @action onPreviousCalendarChange = (dates, _, info) => {
    // if (info.range !== 'end') {
    //   return;
    // }
    this.previousDate = dates;
    this.previousSelected = '自訂';
  };

  @action onCurrentSelect = (e) => {
    this.currentSelected = e;
    switch (e) {
      case 'sevenDays':
        this.currentDate = [dayjs().subtract(7, 'day'), dayjs().subtract(1, 'day')];
        break;
      case 'fourteenDays':
        this.currentDate = [dayjs().subtract(14, 'day'), dayjs().subtract(1, 'day')];
        break;
      case 'thirtyDays':
        this.currentDate = [dayjs().subtract(30, 'day'), dayjs().subtract(1, 'day')];
        break;
      default:
        break;
    }
  };

  @action onPreviousSelect = (e) => {
    this.previousSelected = e;
    switch (e) {
      case 'sevenDays':
        this.previousDate = [dayjs().subtract(7, 'day'), dayjs().subtract(1, 'day')];
        break;
      case 'fourteenDays':
        this.previousDate = [dayjs().subtract(14, 'day'), dayjs().subtract(1, 'day')];
        break;
      case 'thirtyDays':
        this.previousDate = [dayjs().subtract(30, 'day'), dayjs().subtract(1, 'day')];
        break;
      default:
        break;
    }
  };

  @action toggleChecked = () => {
    this.hasCompare = !this.hasCompare;
  };

  @action onConfirm = (callback) => {
    this.checkTimeAvailable();

    this.currentSelectedCache = this.currentSelected;
    this.previousSelectedCache = this.previousSelected;
    this.currentDateCache = [...this.currentDate];
    this.previousDateCache = [...this.previousDate];
    this.hasCompareCache = this.hasCompare;

    if (this.sync) {
      Cookies.set('currentTime', this.currentDate.map((el) => el.format('YYYY-MM-DD')));
      Cookies.set('previousTime', this.previousDate.map((el) => el.format('YYYY-MM-DD')));
    }

    this.open = false;

    callback();
  };

  @action checkTimeAvailable = (current = true, previous = true) => {
    if (!current && !previous) {
      return;
    }
    const [dGte, dLte] = this.disabledDateRange.map((el) => el.valueOf());
    // > check current
    if (current) {
      const [cGte, cLte] = this.currentDate.map((el) => el.valueOf());
      const [acGte, acLte] = [dayjs(dLte).subtract(7, 'day').valueOf(), dayjs(dLte).subtract(1, 'day').valueOf()];
      // ? situation one: all less than, no overlap: preset current seven
      if (cGte < dGte && cLte < dGte) {
        this.currentDate = [dayjs(Math.max(acGte, dGte)), dayjs(acLte)];
      }
      // ? situation two: start less than but end included: start from dGte to cLte
      if (cGte < dGte && cLte > dGte && cLte < dLte) {
        this.currentDate = [dayjs(dGte), dayjs(cLte)];
      }
      // ? situation three: all greater than, no overlap:
      if (cGte > dLte && cLte > dLte) {
        this.currentDate = [dayjs(Math.max(acGte, dGte)), dayjs(acLte)];
      }
      // ? situation four: start included, but end greater than: start from cGte to dLte
      if (cGte < dLte && cGte > dGte && cLte > dLte) {
        this.currentDate = [dayjs(cGte), dayjs(dLte)];
      }
      // ? situation five: start less than and end grater than: use all range
      if (cGte < dGte && cLte > dLte) {
        this.currentDate = [dayjs(dGte), dayjs(dLte)];
      }
    }
    // > check previous
    if (previous) {
      const [pGte, pLte] = this.previousDate.map((el) => el.valueOf());
      // ? situation one: all less than, no overlap: preset current seven
      if (pGte < dGte && pLte < dGte) {
        this.previousDate = [dayjs(dLte), dayjs(dLte)];
        this.hasCompare = false;
        this.hasCompareCache = false;
      }
      // ? situation two: start less than but end included: start from dGte to pLte
      if (pGte < dGte && pLte > dGte && pLte < dLte) {
        this.previousDate = [dayjs(dLte), dayjs(dLte)];
        this.hasCompare = false;
        this.hasCompareCache = false;
      }
      // ? situation three: all greater than, no overlap:
      if (pGte > dLte && pLte > dLte) {
        this.previousDate = [dayjs(dLte), dayjs(dLte)];
        this.hasCompare = false;
        this.hasCompareCache = false;
      }
      // ? situation four: start included, but end greater than: start from pGte to dLte
      if (pGte < dLte && pGte > dGte && pLte > dLte) {
        this.previousDate = [dayjs(dLte), dayjs(dLte)];
        this.hasCompare = false;
        this.hasCompareCache = false;
      }
      // ? situation five: start less than and end grater than: use all range
      if (pGte < dGte && pLte > dLte) {
        this.previousDate = [dayjs(dLte), dayjs(dLte)];
        this.hasCompare = false;
        this.hasCompareCache = false;
      }
    }
  };

  @action onCancel = () => {
    if (!this.open) {
      return;
    }
    this.currentSelected = this.currentSelectedCache;
    this.previousSelected = this.previousSelectedCache;
    this.currentDate = [...this.currentDateCache];
    this.previousDate = [...this.previousDateCache];
    this.hasCompare = this.hasCompareCache;

    this.open = false;
  };

  @action dateRender = (current, info) => {
    const { originNode, type } = info;

    if (type !== 'date') {
      return originNode;
    }

    const value = current.startOf('day').valueOf();
    const style = {};
    if (value < this.currentEnd && value >= this.currentStart) {
      style.background = ' #01A4EA';
      style.width = '100%';
      style.borderRadius = 0;
      style.color = '#393C3E';
    }

    if (value < this.previousEnd && value >= this.previousStart) {
      style.background = ' #F2CE16';
      style.width = '100%';
      style.borderRadius = 0;
      style.color = '#393C3E';
    }

    if (this.overlapping) {
      if (value < this.overlapping[1] && value >= this.overlapping[0]) {
        style.background = ' #45C0B2';
      }
    }

    return (
      <div className="ant-picker-cell-inner" style={style}>
        {current.date()}
      </div>
    );
  };

  @action updateDisabledDateRange = (range) => {
    this.disabledDateRange = range;

    if (!this.isInit) {
      this.initTime();
    }
  };

}
