import { makeObservable, observable, action, computed } from 'mobx';
import dayjs from 'dayjs';

export default class SinglePeriodDatePickerViewModel {
  @observable open = false;

  @observable selected = 'sevenDays';
  @observable selectedCache = 'sevenDays';

  @observable date = [dayjs().subtract(6, 'day'), dayjs()];
  @observable dateCache = [dayjs().subtract(6, 'day'), dayjs()];
  constructor() {
    makeObservable(this);
  }

  @action onOpenChange = (bool) => {
    this.open = bool;
  };

  @action onCalendarChange = (dates, _, info) => {
    console.log('dates', dates);
    if (info.range === 'end') {
      if (!dates[0] || dates[0].valueOf() > dates[1].valueOf()) {
        this.date = [dates[1], dates[1]];
      } else {
        this.date = dates;
      }
    }
    if (info.range === 'start') {
      if (!dates[1] || dates[0].valueOf() > dates[1].valueOf()) {
        this.date = [dates[0], dates[0]];
      } else {
        this.date = dates;
      }
    }
    this.selected = '自訂';
  };

  @action resetDate = () => {
    this.date = [dayjs().subtract(6, 'day'), dayjs()];
    this.dateCache = this.date;
  };

  @action onSelect = (e) => {
    this.selected = e;
    switch (e) {
      case 'sevenDays':
        this.date = [dayjs().subtract(6, 'day'), dayjs()];
        break;
      case 'fourteenDays':
        this.date = [dayjs().subtract(13, 'day'), dayjs()];
        break;
      case 'thirtyDays':
        this.date = [dayjs().subtract(29, 'day'), dayjs()];
        break;
      default:
        break;
    }
  };

  @action onConfirm = (callback) => {
    this.selectedCache = this.selected;
    this.dateCache = [...this.date];

    this.open = false;

    callback();
  };

  @action onCancel = () => {
    this.date = [...this.dateCache];
    this.selected = this.selectedCache;

    this.open = false;
  };
}
