import dayjs from 'dayjs';

export default class BulletinInfoModel {
  constructor(data) {
    const {
      _id,
      title,
      startDate,
      endDate,
      method,
      contents
    } = data;

    const {
      amountToShow,
      frequencies,
      receivers
    } = method.settings;


    this.id = _id;
    this.name = title;
    this.time = [dayjs(startDate), dayjs(endDate)];
    this.amountToShow = amountToShow;
    this.frequencies = frequencies;
    this.receivers = receivers;
    this.contents = contents;

  }

  static fromRes(data) {
    return new BulletinInfoModel(data);
  }
}
